import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { EmailStepComponent } from '@components/email-step/email-step.component';
import { FinalStepComponent } from '@components/final-step/final-step.component';
import { PersonalDataStepComponent } from '@components/personal-data-step/personal-data-step.component';
import { GTM_EVENT_CONSTANT } from '@constants/app.constants';
import { environment } from '@environments/environment';
import { PartnerFormModel } from '@models/partner-form.model';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';
import { HttpClientService } from '@services/http-client.service';
import { LoggerService } from '@services/logger.service';
import { RecaptchaService } from '@services/recaptcha.service';

@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.scss'],
  standalone: true,
  imports: [EmailStepComponent, PersonalDataStepComponent, FinalStepComponent, TranslateModule]
})
export class CreatePartnerComponent implements OnInit {

  @Input() lang = 'de_CH';
  @Input() language: string;
  @Input('partnership-uuid') partnershipUUID: string;
  @Input('ui-mode') uiMode: string;
  @Input('utm-source') utmSource: string;
  @Input() email: string;

  private translate = inject(TranslateService);
  private httpClientService = inject(HttpClientService);
  private recaptchaService = inject(RecaptchaService);
  private analyticsService = inject(AnalyticsService);
  private destroyRef = inject(DestroyRef);

  readonly redirectUrl = environment.redirectDomain;
  readonly gtmEventConstant = GTM_EVENT_CONSTANT;
  protected _recaptchaToken: string;

  partnerFormModel = new PartnerFormModel();
  errorText: string;
  warningText: string;
  isFrame: boolean;
  activeStep = 1;
  partner: any;
  personalFormError = false;
  emailFormError = false;

  ngOnInit() {
    this.lang = this.language || this.lang;
    this.generateRecaptchaToken();

    const params: Params = window.location.search.substring(1).split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});

    this.isFrame = Boolean(params.isFrame);

    if (this.isFrame) {
      this.lang = params.lang || 'de_CH';
      this.partnershipUUID = params['partnership-uuid'];
      this.utmSource = params['utm-source'];
      this.email = params.email;
    }

    this.translate.setDefaultLang(this.lang);

    LoggerService.log('[params] iframe', this.isFrame);
    LoggerService.log('[params] partnership-uuid', this.partnershipUUID);
    LoggerService.log('[params] utm-source', this.utmSource);
    LoggerService.log('[params] email', this.email);

    // Pre-select email
    if (this.email) {
      this.partnerFormModel.email = this.email;
    }
  }

  generateRecaptchaToken(): void {
    this.recaptchaService.generateToken()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: token => this._recaptchaToken = token,
        error: (error: HttpErrorResponse) => LoggerService.error(error)
      });
  }

  submitPartnerData(personalData: PartnerFormModel): void {
    this.resetErrorState();
    this.partnerFormModel = personalData;
    this.partnerFormModel.language_identifier = this.lang;
    this.partnerFormModel.partnership_uuid = this.partnershipUUID;
    this.partnerFormModel.utm_source = this.utmSource;
    this.partnerFormModel.token = this._recaptchaToken;

    this.httpClientService.post('partners', this.partnerFormModel, {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          if (result?.errors?.email?.unique) {
            // email unique error
            this.warningText = 'email_error';
            this.activeStep = 1;
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2_VALIDATION_FAILED, {
              error: { email: { unique: true } }
            });
          } else if (result?.errors?.name?.unique) {
            // name unique error
            this.errorText = "error";
            this.personalFormError = true;
            this.activeStep = 2;

            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2_VALIDATION_FAILED, {
              error: { name: { unique: true } }
            });
          } else {
            this.partner = result;
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2, {
              partner: { ...result }
            });
            this.activeStep = 3;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.personalFormError = true;
          if (error.status === 400) {
            // Token missing
            this.errorText = "recaptcha_token_missing_error";
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2_VALIDATION_FAILED, {
              error: { token: { required: true } }
            });
          } else if (error.status === 401) {
            // Invalid token
            this.errorText = 'invalid_recaptcha_token_error'
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2_VALIDATION_FAILED, {
              error: { token: { invalid: true } }
            });
          } else {
            this.errorText = "error";
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_2_SERVER_ERROR, {
              error
            });
          }
        }
      });
  }

  saveEmail(email: string): void {
    this.resetErrorState();
    this.httpClientService.post(`partners/check_email`, { email: email }, {})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.partnerFormModel.email = email;
          this.activeStep = 2;
          this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_1, {
            email
          });
        },
        error: (error: HttpErrorResponse) => {
          this.emailFormError = true;
          if (error.status === 409) {
            this.warningText = 'email_error';
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_1_VALIDATION_FAILED, {
              error: { email: { unique: true } }
            });
          } else if (error.status === 403) {
            this.warningText = 'email_banned_domains';
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_1_VALIDATION_FAILED, {
              error: { email: { banned_domain: true } }
            });
          } else {
            this.errorText = 'exceptions.somethingWentWrong'
            this.analyticsService.emitGTMEvent(this.gtmEventConstant.STEP_1_VALIDATION_FAILED, {
              error: { email: { somethingWentWrong: error } }
            });
          }
          LoggerService.error(error);
        }
      });
  }

  resetErrorState(): void {
    this.warningText = undefined;
    this.errorText = undefined;
    this.personalFormError = false;
    this.emailFormError = false;
  }
}